import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { Header } from '../../../organisms';
import style from './Step6.module.scss';

const cx = classNames.bind(style);
export function Step6(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [hasPsychiatricDisorder, setHasPsychiatricDisorder] = useState<undefined | boolean>();
  const [hasLiverDisorder, setHasLiverDisorder] = useState<undefined | boolean>();
  const [hasCardiovascularDisease, setHasCardiovascularDisease] = useState<undefined | boolean>();
  const [hasPanicAttacks, setHasPanicAttacks] = useState<undefined | boolean>();
  const [hasPersonalityDisorder, setHasPersonalityDisorder] = useState<undefined | boolean>();
  const [hasEpilepsy, setHasEpilepsy] = useState<undefined | boolean>();
  const [personalityDisorder, setPersonalityDisorder] = useState('');
  const [hasAddictionDisorder, setHasAddictionDisorder] = useState<undefined | boolean>();
  const [isPregnant, setIsPregnant] = useState<undefined | boolean>();
  const [symptomComment, setSymptomComment] = useState('');

  useEffect(() => {
    const step5 = sessionStorage.getItem('questionnaire-step5');
    if (!step5) {
      navigation('/questionnaire/step-5');
    }
    const step6 = sessionStorage.getItem('questionnaire-step6');
    if (step6 !== null) {
      const parsedData = JSON.parse(step6);
      setHasPsychiatricDisorder(parsedData.hasPsychiatricDisorder);
      setHasLiverDisorder(parsedData.hasLiverDisorder);
      setHasCardiovascularDisease(parsedData.hasCardiovascularDisease);
      setHasPanicAttacks(parsedData.hasPanicAttacks);
      setHasPersonalityDisorder(parsedData.hasPersonalityDisorder);
      setPersonalityDisorder(parsedData.personalityDisorder);
      setHasEpilepsy(parsedData.hasEpilepsy);
      setHasAddictionDisorder(parsedData.hasAddictionDisorder);
      setIsPregnant(parsedData.isPregnant);
      setSymptomComment(parsedData.symptomComment);
    }
    setLoading(false);
  }, []);

  const isButtonDisabled =
    hasPsychiatricDisorder === undefined ||
    hasLiverDisorder === undefined ||
    hasCardiovascularDisease === undefined ||
    hasPanicAttacks === undefined ||
    hasPersonalityDisorder === undefined ||
    (hasPersonalityDisorder && personalityDisorder === '') ||
    hasEpilepsy === undefined ||
    hasAddictionDisorder === undefined ||
    isPregnant === undefined ||
    symptomComment === '';

  const nextStep = () => {
    const stepData = {
      hasPsychiatricDisorder,
      hasLiverDisorder,
      hasCardiovascularDisease,
      hasPanicAttacks,
      hasPersonalityDisorder,
      personalityDisorder: hasPersonalityDisorder ? personalityDisorder : '',
      hasEpilepsy,
      hasAddictionDisorder,
      isPregnant,
      symptomComment,
    };
    sessionStorage.setItem('questionnaire-step6', JSON.stringify(stepData));
    navigation('/questionnaire/step-7');
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const radioContainerClasses = cx({ RadioContainer: true }, 'flex', 'mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <Header barTitle='Daten vervollständigen' showBackButton={true} />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={6} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Fast geschafft</Headline>
        </div>

        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Leiden Sie (oder litten Sie in der Vergangenheit) an einer Psychose, Schizophrenie, Manie oder
                  Bipolaren Erkrankung?
                </Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasPsychiatricDisorderTrue'}
                    name={'hasPsychiatricDisorder'}
                    value={'true'}
                    defaultChecked={hasPsychiatricDisorder === true}
                    onChange={($event) => setHasPsychiatricDisorder($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasPsychiatricDisorderFalse'}
                    name={'hasPsychiatricDisorder'}
                    value={'false'}
                    defaultChecked={hasPsychiatricDisorder === false}
                    onChange={($event) => setHasPsychiatricDisorder($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Liegt bei Ihnen eine Einschränkung der Leber oder Nierenfunktion vor?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasLiverDisorderTrue'}
                    name={'hasLiverDisorder'}
                    value={'true'}
                    defaultChecked={hasLiverDisorder === true}
                    onChange={($event) => setHasLiverDisorder($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasLiverDisorderFalse'}
                    name={'hasLiverDisorder'}
                    value={'false'}
                    defaultChecked={hasLiverDisorder === false}
                    onChange={($event) => setHasLiverDisorder($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Leiden Sie an einer Herz-Kreislauf- Erkrankung (z.B Herzinsuffizienz oder Herzrhythmusstörungen)?
                </Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasCardiovascularDiseaseTrue'}
                    name={'hasCardiovascularDisease'}
                    value={'true'}
                    defaultChecked={hasCardiovascularDisease === true}
                    onChange={($event) => setHasCardiovascularDisease($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasCardiovascularDiseaseFalse'}
                    name={'hasCardiovascularDisease'}
                    value={'false'}
                    defaultChecked={hasCardiovascularDisease === false}
                    onChange={($event) => setHasCardiovascularDisease($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Leiden oder litten Sie schonmal an Panikattacken oder einer Angststörung?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasPanicAttacksTrue'}
                    name={'hasPanicAttacks'}
                    value={'true'}
                    defaultChecked={hasPanicAttacks === true}
                    onChange={($event) => setHasPanicAttacks($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasPanicAttacksFalse'}
                    name={'hasPanicAttacks'}
                    value={'false'}
                    defaultChecked={hasPanicAttacks === false}
                    onChange={($event) => setHasPanicAttacks($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Wurde bei Ihnen jemals eine Persönlichkeitsstörung diagnostiziert?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasPersonalityDisorderTrue'}
                    name={'hasPersonalityDisorder'}
                    value={'true'}
                    defaultChecked={hasPersonalityDisorder === true}
                    onChange={($event) => setHasPersonalityDisorder($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasPersonalityDisorderFalse'}
                    name={'hasPersonalityDisorder'}
                    value={'false'}
                    defaultChecked={hasPersonalityDisorder === false}
                    onChange={($event) => setHasPersonalityDisorder($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
              {hasPersonalityDisorder && (
                <div>
                  <InputField
                    type={'textarea'}
                    placeholder={'Welche Persönlichkeitsstörung?'}
                    onChange={setPersonalityDisorder}
                    value={personalityDisorder}
                  />
                </div>
              )}
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Leiden Sie unter Epilepsie?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasEpilepsyTrue'}
                    name={'hasEpilepsy'}
                    value={'true'}
                    defaultChecked={hasEpilepsy === true}
                    onChange={($event) => setHasEpilepsy($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasEpilepsyFalse'}
                    name={'hasEpilepsy'}
                    value={'false'}
                    defaultChecked={hasEpilepsy === false}
                    onChange={($event) => setHasEpilepsy($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Leiden oder litten Sie schonmal an einer Suchterkrankung (z.B. Substanzsucht wie Alkohol, Medikamente
                  oder Drogen)?
                </Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasAddictionDisorderTrue'}
                    name={'hasAddictionDisorder'}
                    value={'true'}
                    defaultChecked={hasAddictionDisorder === true}
                    onChange={($event) => setHasAddictionDisorder($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasAddictionDisorderFalse'}
                    name={'hasAddictionDisorder'}
                    value={'false'}
                    defaultChecked={hasAddictionDisorder === false}
                    onChange={($event) => setHasAddictionDisorder($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Sind Sie aktuell schwanger, könnten schwanger sein oder sind in der Stillzeit?</Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'isPregnantTrue'}
                    name={'isPregnant'}
                    value={'true'}
                    defaultChecked={isPregnant === true}
                    onChange={($event) => setIsPregnant($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'isPregnantFalse'}
                    name={'isPregnant'}
                    value={'false'}
                    defaultChecked={isPregnant === false}
                    onChange={($event) => setIsPregnant($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Hier können Sie Ihre Beschwerden genauer beschreiben und Ihrem Ihrem Arzt weitere Informationen
                  mitteilen.
                </Label>
              </div>
              <div>
                <InputField type={'textarea'} onChange={setSymptomComment} value={symptomComment} />
              </div>
            </div>

            <div className={buttonContainerClasses}>
              <Button onClick={nextStep} disabled={isButtonDisabled}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
